<template>
  <div id="app">
    <h1>Sistema de Análise de Apostas</h1>
    <BilheteList />
  </div>
</template>

<script>
import BilheteList from './components/BilheteList.vue';

export default {
  components: {
    BilheteList
  }
};
</script>
