<template>
  <div>
    <h2>Bilhetes</h2>
    <ul>
      <li v-for="bilhete in bilhetes" :key="bilhete.id">
        {{ bilhete.nome }} - {{ bilhete.valor }}
      </li>
    </ul>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import api from '../services/api';

export default {
  setup() {
    const bilhetes = ref([]);

    const fetchBilhetes = async () => {
      try {
        const response = await api.get('/bets');
        bilhetes.value = response.data;
      } catch (error) {
        console.error('Erro ao buscar bilhetes:', error);
      }
    };

    onMounted(() => {
      fetchBilhetes();
    });

    return {
      bilhetes
    };
  }
};
</script>

<style scoped>
/* Adicione seu estilo aqui */
</style>
